@import '../common';

.footer {
  width: 100%;
  background-color: $black1;
}

.container {
  display: flex;

  box-sizing: border-box;
  width: 100%;
  max-width: 1274px;
  margin: 0 auto;
  padding: 110px 24px 37px;

  &_copyright {
    justify-content: space-between;
    padding-top: 3px;
  }
}

.main {
  position: relative;

  display: flex;
  flex: 1;
  flex-flow: column wrap;

  box-sizing: border-box;
  max-height: 255px;
  margin: 0;
  padding: 0;
}

.title {
  position: absolute;
  top: -50px;
  left: 0;

  font-size: 20px;
  font-weight: 600;
  line-height: 100%;
}

.about {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 36px;

  width: 34%;
}

.about_box {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;

  margin-top: 10px;
}

.text {
  font-size: 20px;
  font-weight: 400;
  line-height: 100%;
  color: $text-white;
  text-decoration: none;
}

.phone {
  font-weight: 500;
}

.text_box {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
}

.copyright_text {
  font-size: 16px;
  font-weight: 400;
  line-height: 100%;
  color: $text-gray;
}

.link {
  transition: $common-transition;
}

.link:hover {
  opacity: $common-hover-opacity;
}

@media screen and (max-width: 1000px) {
  .container {
    flex-direction: column;

    &_copyright {
      flex-direction: row;
    }
  }

  .about {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    margin-top: 80px;
  }

  .about_box {
    width: 34%;
    margin-top: 0;
  }
}

@media screen and (max-width: 900px) {
  .container {
    padding: 110px 24px 37px;

    &_copyright {
      padding-top: 0;
    }
  }
}

@media screen and (max-width: 700px) {
  .container {
    padding: 80px 16px 30px;

    &_copyright {
      padding-top: 0;
    }
  }

  .about_box {
    width: fit-content;
  }

  .social_links {
    width: fit-content;
  }
}

@media screen and (max-width: 570px) {
  .main {
    max-height: 100%;
  }

  .about {
    flex-direction: column;
    align-items: flex-start;
  }

  .about_box {
    margin-top: 10px;
  }

  .about_text {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.2;
  }

  .copyright_text {
    font-size: 13px;
  }

  .container_copyright {
    flex-direction: column;
    gap: 30px;
  }

  .social_links {
    order: -1;
  }
}
