@import '../../common';

.paragraph {
  margin: 0;

  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  color: $text-dark;
  text-align: center;
}

.error {
  font-size: 14px;
  line-height: 120%;
  color: $red1;
}

.gradient {
  background-image: $blue-gradient1;
  background-clip: text;

  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}
