@import '../../common';

.list {
  display: flex;
  gap: 24px;
  align-items: center;

  margin: 0;
  padding: 0;

  &_item {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  &_link {
    position: relative;

    display: flex;

    width: 46px;
    height: 46px;

    text-decoration: none;

    &_btn {
      margin: 0;
      padding: 0;
      background: none;
      border: none;
    }
  }

  .icon {
    transition: $common-transition;

    &_hover {
      position: absolute;
      top: 0;
      left: 0;

      visibility: hidden;
      opacity: 0;

      transition: $common-transition;
    }
  }

  &_link:hover {
    .icon {
      visibility: hidden;
      opacity: 0;
      transition: $common-transition;

      & + .icon_hover {
        visibility: visible;
        opacity: 1;
        transition: $common-transition;
      }
    }
  }

  &_item_search {
    display: none;
  }

  .count {
    position: absolute;
    top: 0;
    right: 0;
  }
}

@media screen and (max-width: 900px) {
  .icon {
    width: 36px;
    height: 36px;

    &_hover {
      width: 36px;
      height: 36px;
    }
  }

  .list {
    gap: 15px;

    &_link {
      width: 36px;
      height: 36px;
    }
  }
}

@media screen and (max-width: 750px) {
  .list {
    &_item_search {
      display: block;
    }
  }

  .icon_search {
    width: 24px;
    height: 24px;
    margin: auto;
  }
}

@media screen and (max-width: 550px) {
  .list {
    &_item_mob_none {
      display: none;
    }

    &_link:hover {
      .icon {
        visibility: visible;
        opacity: 1;

        & + .icon_hover {
          visibility: hidden;
          opacity: 0;
        }
      }
    }
  }
}
