.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
  max-width: 1226px;
  margin: 0 auto;
}

.title_box {
  margin-top: 43px;
  margin-bottom: 38px;
}

.title {
  display: inline-block;
  margin-right: 16px;
  text-align: left;

  &_q {
    display: inline-block;

    font-size: 28px;
    font-weight: 500;
    font-style: normal;
    line-height: 150%;
  }
}

.list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, auto));
  gap: 24px 19px;
  justify-items: center;

  width: 100%;
  margin: 0 0 24px;
  padding: 0;
}

.sort_box {
  margin-bottom: 36px;
}

.pagination {
  align-self: center;
  margin-top: 30px;
}

.not_found {
  align-self: center;
}

@media screen and (max-width: 500px) {
  .list {
    margin-bottom: 16px;
  }

  .title_box {
    margin-top: 24px;
    margin-bottom: 13px;
  }

  .title {
    &_q {
      font-size: 26px;
      font-weight: 500;
      font-style: normal;
      line-height: 125%;
    }
  }

  .sort_box {
    margin-bottom: 29px;
  }
}

@media screen and (max-width: 400px) {
  .not_found {
    align-self: stretch;
  }
}
