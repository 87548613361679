@import '../common';

.title {
  margin-top: 26px;
  margin-bottom: 16px;
  line-height: 1;
}

.order_text {
  font-size: 16px;
  font-weight: 400;
  line-height: 100%;
  color: $text-black;
}

.wait_call_text {
  margin-top: 4px;
  margin-bottom: 26px;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: $text-gray;
}

.img {
  width: 332px;
  height: 247px;
}

@media screen and (max-width: 450px) {
  .img {
    width: 100%;
  }
}
