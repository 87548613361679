@import '../../common';

.input_field {
  position: relative;
  display: flex;
  flex-direction: column;
}

.input::placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 120%;
  color: $text-gray;
}

.input {
  box-sizing: border-box;
  height: 51px;
  padding: 16px;

  font-size: 16px;
  font-weight: 400;
  line-height: 120%;
  color: $text-black;

  background-color: $text-white;
  border: 1px solid $gray3;
  border-radius: $common-small-border-radius;

  &_error {
    color: $red1;
    border: 1px solid $red1;
  }

  &_error::placeholder {
    color: $red1;
  }
}

.label {
  position: absolute;
  top: 0;
  left: 0;

  margin-bottom: 5px;
  margin-left: 8px;

  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: $text-black;
}

.input:focus {
  background-image: linear-gradient(white, white), $blue-gradient1;
  background-clip: padding-box, border-box;
  background-origin: border-box;
  border: double 1px transparent;
  border-radius: $common-small-border-radius;
  outline: none;
}

.input:focus + label {
  background-image: $blue-gradient1;
  background-clip: text;

  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.extratop {
  margin-top: 24px;
}

.error {
  align-self: flex-start;

  margin-top: 4px;
  margin-bottom: 4px;

  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
  color: $red1;
}

.small {
  height: 40px;
  padding: 8px 12px;
  font-size: 18px;
  line-height: 130%;
}
