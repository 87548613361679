@import '../common';

.container {
  display: flex;
  align-items: center;

  .img {
    cursor: pointer;
    width: 72px;
    height: 72px;
    object-fit: contain;
  }

  .main_box {
    display: grid;
    grid-template-columns: 60% 1fr;
    gap: 20px;
    width: 100%;

    .desc {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .name {
        margin-bottom: 8px;

        font-size: 14px;
        font-weight: 600;
        line-height: 105%;
        color: $text-black;
        text-align: left;
      }

      &_text {
        font-size: 12px;
        font-weight: 400;
        line-height: 105%;
      }
    }

    .price_box {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .count {
        font-size: 14px;
        font-weight: 400;
        line-height: 100%;
      }

      .price {
        font-size: 14px;
        font-weight: 400;
        line-height: 100%;
        color: $text-black;
      }
    }
  }
}

@container (width <= 600px) {
  .container {
    .price_box {
      flex-direction: column;
      gap: 8px;
      align-items: flex-end;
    }
  }
}

@container (width <= 500px) {
  .container {
    gap: 16px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(205 205 205 / 30%);

    .img {
      align-self: flex-start;
      width: 40px;
      height: 40px;
      padding: 12px;
    }

    .main_box {
      grid-template-columns: 1fr;
      gap: 14px;

      .desc {
        .name {
          font-size: 16px;
        }

        &_text {
          font-size: 14px;
        }
      }

      .price_box {
        flex-direction: row;
        align-items: flex-end;

        .price {
          font-size: 16px;
        }
      }
    }
  }
}
