.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.title {
  margin-top: 43px;
  margin-bottom: 38px;
  text-align: left;
}

.list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, auto));
  gap: 24px 19px;
  justify-items: center;

  width: 100%;
  margin: 0 0 24px;
  padding: 0;
}

.sort_box {
  margin-bottom: 36px;
}

.pagination {
  align-self: center;
  margin-top: 30px;
}

@media screen and (max-width: 500px) {
  .list {
    margin-bottom: 16px;
  }

  .title {
    margin-top: 24px;
    margin-bottom: 13px;
  }

  .sort_box {
    margin-bottom: 29px;
  }
}
