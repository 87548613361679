@import '../../common';

.form {
  position: relative;
  width: 100%;
}

.input {
  box-sizing: border-box;
  width: 100%;
  padding: 14px 48px 14px 24px;

  font-size: 16px;
  font-weight: 400;
  line-height: 120%;
  color: $text-black;

  background-color: $text-white;
  border: none;
  border-radius: $common-extralarge-border-radius;
  outline: none;
}

.input::placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 120%;
  color: $text-dark;
}

.search_btn {
  cursor: pointer;

  position: absolute;
  z-index: 3;
  top: 11px;
  right: 24px;

  margin: 0;
  padding: 0;

  background: none;
  border: none;

  &_mob {
    top: 7px;
    right: auto;
    left: 9px;
  }
}

.close {
  cursor: pointer;

  position: absolute;
  z-index: 3;
  top: 11px;
  right: 58px;

  &_mob {
    top: 7px;
    right: 15px;
  }
}

@media screen and (max-width: 1250px) {
  .form {
    min-width: auto;
  }
}
