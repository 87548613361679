.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.title {
  margin-top: 45px;
  margin-bottom: 16px;
  font-weight: 600;
}

.img {
  width: 100%;
  max-width: 249px;
  margin-top: 12px;
}

.btn {
  min-width: 223px;
}

@media screen and (max-width: 800px) {
  .img {
    margin-top: 10px;
  }
}

@media screen and (max-width: 500px) {
  .container {
    align-items: stretch;
  }

  .img {
    align-self: center;
  }
}
