@import '../common';

.container {
  cursor: pointer;

  width: 292px;
  margin: 10px;

  list-style-type: none;

  border-radius: 8px;

  transition: $common-transition;

  &:hover {
    box-shadow: 0 0 10px 4px $gray3;
  }
}

.article {
  position: relative;

  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  width: 292px;
  height: 424px;

  text-decoration: none;
}

.img {
  width: 292px;
  height: 100%;
  max-height: 292px;
  margin-top: 16px;
  margin-bottom: 15px;

  object-fit: contain;

  &_empty {
    opacity: 0.6;
  }
}

.like {
  cursor: pointer;

  position: absolute;
  top: 16px;
  right: 8px;

  width: 35px;
  height: 35px;

  background: none;
  background-image: url('../../images/icons/favorite.svg');
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  outline: none;

  transition: $common-transition;

  &_active {
    background-image: url('../../images/icons/favorite-active.svg');
  }

  &:disabled {
    animation: like 0.6s infinite;
  }
}

.like:hover {
  opacity: $common-hover-opacity;
}

.tabs {
  position: absolute;
  top: 16px;
  left: 8px;
  align-items: flex-start;
}

.name {
  overflow: hidden;
  display: -webkit-box;

  height: 48px;
  margin-top: 2px;
  margin-bottom: 16px;

  line-height: 150%;
  color: $text-black;
  text-align: left;
  text-overflow: ellipsis;

  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
}

.addition_box {
  padding: 0 8px;
}

.btn {
  margin-bottom: 16px;
}

@media screen and (max-width: 500px) {
  .container {
    user-select: none;

    width: 100%;
    margin: 0;

    -webkit-tap-highlight-color: transparent;
    -webkit-tap-highlight-color: rgb(0 0 0 / 0%);

    &:hover {
      background: none;
    }
  }

  .article {
    flex-direction: row;
    gap: 6px;

    width: 100%;
    height: 140px;
    margin-bottom: 8px;
  }

  .tabs {
    position: static;
    flex-direction: row;
    margin-right: auto;
  }

  .like {
    position: static;
    order: 1;
    width: 32px;
    height: 32px;
  }

  .tags_like_box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .addition_box {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    width: 100%;
    padding: 0;
  }

  .img {
    width: 86px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .name {
    margin: 0;
    white-space: normal;
  }
}

@keyframes like {
  33% {
    scale: 1.2;
  }

  66% {
    scale: 0.8;
  }

  100% {
    scale: 1;
  }
}
