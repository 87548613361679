@import '../../common';

.container {
  position: relative;
  display: flex;
  align-items: center;
}

.text {
  font-size: 18px;
  font-weight: 500;
  line-height: 150%;
  color: $text-black;
}

.select {
  cursor: pointer;

  position: relative;
  z-index: 1;

  margin-left: 8px;
  padding: 0;

  // appearance: none;
  background: none;
  border: none;
  outline: none;
}

// .icon {
//   position: absolute;
//   top: 0;
//   right: 0;
// }

.img {
  transition: $common-transition;
}

.select:checked .img {
  transform: rotate(90deg);
}

@media screen and (max-width: 970px) {
  .mob_text {
    display: none;
  }

  .select {
    margin-left: 0;
  }
}

@media screen and (max-width: 500px) {
  .text {
    font-size: 16px;
  }

  .mob_text {
    display: none;
  }
}
