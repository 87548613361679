@import '../../common';

.container {
  box-sizing: border-box;
  min-width: 19px;
  padding: 4px;

  background: $orange-gradient;
  border-radius: 29px;
}

.text {
  font-size: 10px;
  font-weight: 400;
  line-height: 110%;
  color: $text-white;
  text-align: center;
}
