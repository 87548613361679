@import '../../common';

.container {
  display: flex;
  gap: 8px;
  align-items: center;

  padding: 0;

  font-size: 18px;
  font-weight: 500;
  line-height: 150%;
  color: $text-black;

  background: none;
  border: none;
}

@media screen and (max-width: 500px) {
  .container {
    font-size: 16px;
  }
}
