.container {
  position: relative;
  display: flex;
  flex-direction: column;

  .btn_back {
    position: absolute;
    top: -36px;
    left: 0;
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 32px;

  margin: 0 0 40px;
  padding: 0;
}

.pagination {
  align-self: center;
  margin-top: 30px;
}

@media screen and (max-width: 500px) {
  .list {
    margin-bottom: 32px;
  }

  .container {
    .btn_back {
      top: 0;
    }
  }
}
