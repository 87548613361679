@import '../../common';

.container {
  width: 100%;
}

.inputs_box {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  justify-items: center;

  width: 100%;
  max-width: 400px;
}

.input {
  box-sizing: border-box;
  padding-top: 10px;
  padding-bottom: 10px;

  font-size: 16px;
  font-weight: 400;
  line-height: 120%;
  text-align: center;
}

.field {
  max-width: 88px;
}

.fill {
  background-image: linear-gradient(white, white), $blue-gradient1;
  background-clip: padding-box, border-box;
  background-origin: border-box;
  border: double 1px transparent;
  border-radius: $common-small-border-radius;
  outline: none;
}

.error {
  margin-bottom: 4px;

  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
  color: $red1;
  text-align: left;
}

.error_input {
  color: $red1;
  background-image: none;
  border: 1px solid $red1;
}

@media screen and (max-width: 900px) {
  .field {
    max-width: 68px;
  }
}

@media screen and (max-width: 750px) {
  .field {
    max-width: 88px;
  }
}

@media screen and (max-width: 450px) {
  .field {
    max-width: 68px;
  }
}

@media screen and (max-width: 350px) {
  .inputs_box {
    gap: 4px;
  }
}
