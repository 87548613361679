@import '../../common';

.button {
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 12px 34px;

  border: none;
  border-radius: $common-large-border-radius;

  transition: $common-transition;

  .text {
    font-size: 18px;
    font-weight: 500;
    line-height: 130%;
    color: $gray2;
    text-align: center;
  }

  &_type_primary {
    background: $blue-gradient1;
  }

  // &_type_primary:hover {
  //   box-shadow: $blue-gradient1-shadow;
  //   filter: $blue-gradient1-filter;
  // }

  &_type_secondary {
    background: $text-gray;
  }

  &_type_menu {
    padding: 12px 20px;
    color: $text-white;
    background: $blue-gradient1;

    .text {
      color: $text-white;
    }
  }

  &_type_addition {
    padding: 10px 30px;
    background: none;
    border: 1px solid $text-dark;

    .text {
      color: $text-black;
    }
  }

  &_type_logout {
    padding: 13px 30px;
    background: none;
    border: 2px solid $gray3;

    .text {
      color: $gray3;
    }
  }

  &_type_load {
    width: 100%;
    height: 60px;
    padding-top: 18px;
    padding-bottom: 18px;

    background-color: $gray2;

    .text {
      color: $text-black;
    }
  }

  &_type_item-missing {
    background-color: $text-dark;

    .text {
      color: $text-white;
    }
  }

  &_type_sign-in {
    width: 100%;
    max-width: 400px;
    background: $blue-gradient1;
    border-radius: $common-small-border-radius;
  }

  &_type_delay {
    width: 100%;
    max-width: 320px;
    background: $text-gray;
    border-radius: $common-small-border-radius;
  }

  &_type_order {
    background: none;
    background-image: linear-gradient(white, white), $blue-gradient1;
    background-clip: padding-box, border-box;
    background-origin: border-box;
    border: double 1px transparent;

    .text {
      color: $text-black;
    }
  }

  &_type_main-slide {
    color: $text-white;
    background: $gray-gradient;
  }

  &_type_text-only {
    padding: 0;
    background: none;

    .text {
      font-size: 14px;

      background-image: $blue-gradient1;
      background-clip: text;

      -webkit-text-fill-color: transparent;
      -moz-text-fill-color: transparent;
    }
  }

  &_type_primary:hover,
  &_type_sign-in:hover,
  &_type_menu:hover {
    box-shadow: $blue-gradient1-shadow;
  }

  &_type_main-slide:hover {
    filter: $gray-gradient1-filter;
    box-shadow: $gray-gradient1-shadow;
  }

  &_type_secondary:hover,
  &_type_addition:hover,
  &_type_load:hover,
  &_type_item-missing:hover,
  &_type_delay:hover,
  &_type_order:hover,
  &_type_text-only:hover {
    opacity: $common-hover-opacity;
  }

  &_icon {
    max-width: 18px;
    margin-right: 8px;
  }

  &:disabled {
    opacity: 0.6;
    background: $gray3;
    filter: none;
    box-shadow: none;
  }
}

@media screen and (max-width: 550px) {
  .button {
    &_type {
      &_menu {
        user-select: none;

        width: 36px;
        height: 36px;
        padding: 10px;

        -webkit-tap-highlight-color: transparent;
        -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
      }

      &_text-only {
        .text {
          font-size: 16px;
        }
      }

      &_main-slide {
        width: 100%;
      }
    }

    &_icon {
      margin: 0;
    }
  }

  .text_none {
    display: none;
  }
}

// @media screen and (max-width: 425px) {
//   .button {
//     width: 100%;
//   }
// }
