@import '../../common';

.container {
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  padding: 0 10px;

  text-decoration: none;

  background: $gray2;
  border-radius: $common-small-border-radius;

  transition: $common-transition;

  &_25 {
    background: $blue-gradient1;
  }
}

.container:hover {
  transform: scale(1.03);
}

.text {
  font-size: 28px;
  font-weight: 600;
  line-height: 100%;

  &_percent {
    margin: 11px auto 19px;
    font-size: 100px;
    font-weight: 800;
    line-height: 100%;
  }

  &_link {
    font-size: 18px;
    font-weight: 500;
    line-height: 150%;
  }

  &_15 {
    color: $text-black;
  }

  &_25 {
    color: $text-white;
  }
}

.link {
  display: flex;
  align-items: center;
}

.icon {
  margin-left: 8px;
  fill: $blue-gradient1;

  &_15 {
    fill: $text-black;
  }

  &_25 {
    fill: $text-white;
  }

  &_40 {
    fill: url('../../common.scss');
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 30px 0;
  }

  .text {
    &_percent {
      margin: 12px auto 16px;
      font-size: 80px;
    }
  }
}
