@import '../../common';

.container {
  width: 100%;
  border-top: 1px solid $text-black;
}

.btn {
  cursor: pointer;

  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: 14px 0;

  background: none;
  border: none;
}

.img {
  transition: $common-transition;

  &_open {
    transform: rotate(-180deg);
  }
}

.list {
  overflow: hidden;

  max-height: 0;
  margin: 0;
  padding: 0;

  transition: $common-transition;

  &_item {
    display: flex;
    gap: 2px;
    list-style-type: none;
  }

  &_item::before {
    content: '—';
  }

  &_open {
    margin-top: 8px;
    margin-bottom: 24px;
  }
}

.title {
  text-align: left;
}

.text {
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 20px;
  color: $gray6;
  text-align: left;
}
