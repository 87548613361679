.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  .page_title {
    align-self: flex-start;
  }
}

.title {
  margin-top: 89px;
  font-weight: 600;
}

.text {
  margin: 4px auto 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
}

.img {
  width: 100%;
  max-width: 609px;
  margin-top: 74px;
}

.btn {
  min-width: 223px;
}

@media screen and (max-width: 800px) {
  .img {
    margin-top: 55px;
  }
}
