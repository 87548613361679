@import '../../common';

.loader {
  position: relative;
  margin: 0 auto;
  perspective: 120px;
  border-radius: 100%;

  &_full {
    position: fixed;
    z-index: 11;
    top: 50%;
    left: 50%;
    translate: (-50% -50%);
  }
}

.overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: $overlay-color;
}

.loader::before {
  content: '';

  position: absolute;
  top: 25px;
  left: 25px;

  width: 50px;
  height: 50px;

  background: $blue-gradient1;

  animation: flip 1s infinite;
}

.s {
  width: 20px;
  height: 20px;
}

.s::before {
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
}

.m {
  width: 100px;
  height: 100px;
}

@keyframes flip {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotateY(180deg);
  }

  100% {
    transform: rotateY(180deg) rotateX(180deg);
  }
}
