@import '../../components/common';

.container {
  display: flex;
  flex-direction: column;
  gap: 50px;

  .data {
    &_box {
      padding: 42px 50px 45px;
      background-color: $gray7;
      border-radius: $common-small-border-radius;

      .title {
        overflow: hidden;
        margin-bottom: 36px;
        text-align: left;
        text-overflow: ellipsis;
      }

      .btn {
        &_edit {
          margin-top: 40px;
        }
      }
    }

    &_list {
      display: grid;
      grid-template-columns: 137px 1fr;
      gap: 30px 0;

      .fieldname {
        font-size: 16px;
        font-weight: 400;
        line-height: 140%;
        text-align: left;
      }

      .fieldvalue {
        overflow: hidden;

        font-size: 22px;
        font-weight: 600;
        line-height: 100%;
        text-align: left;
        text-overflow: ellipsis;
      }
    }
  }

  .status {
    &_box {
      position: relative;
      padding: 17px 50px 43px;
      background-color: $gray7;
      border-radius: $common-small-border-radius;

      .title {
        text-align: left;
      }

      .subtitle {
        margin-top: 4px;
        margin-bottom: 26px;

        font-size: 14px;
        font-weight: 400;
        line-height: 120%;
        text-align: left;
      }

      .progressbar {
        width: 100%;
        height: 4px;
        margin-top: 30px;
        background-color: $gray1;

        .current {
          max-width: 100%;
          height: 4px;
          background: $blue-gradient1;
        }
      }
    }

    &_line {
      position: relative;
      display: flex;
      justify-content: space-between;

      &_box {
        position: relative;
        width: 120px;

        &_text {
          margin-top: 4px;
          font-size: 14px;
          font-weight: 400;
          line-height: 120%;

          &_first {
            text-align: left;
          }

          &_last {
            text-align: right;
          }
        }

        &_title {
          &_first {
            text-align: left;

            &::before {
              content: '';

              position: absolute;
              top: 65px;
              left: 0;

              display: block;

              width: 18px;
              height: 18px;

              background-color: $gray1;
              border-radius: 50%;
            }
          }

          &_last {
            text-align: right;

            &::before {
              content: '';

              position: absolute;
              top: 65px;
              right: 0;

              display: block;

              width: 18px;
              height: 18px;

              background-color: $gray1;
              border-radius: 50%;
            }
          }

          &_middle {
            &::before {
              content: '';

              position: absolute;
              top: 65px;
              left: 50%;
              translate: -50% 0;

              display: block;

              width: 18px;
              height: 18px;

              background-color: $gray1;
              border-radius: 50%;
            }
          }

          &_active {
            &::before {
              z-index: 1;
              top: 62px;

              width: 24px;
              height: 24px;

              background: $blue-gradient1;
            }
          }
        }
      }
    }
  }

  .edit {
    &_main_box {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 35px;
      max-width: 555px;

      .input {
        &_error {
          color: $red1;
          border: 1px solid $red1;
        }

        &_error::placeholder {
          color: $red1;
        }
      }
    }

    &_password_box {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 35px;

      max-width: 555px;
      margin-top: 35px;
    }

    &_btns_box {
      display: flex;
      gap: 30px;
      margin-top: 57px;
    }

    &_phone_box {
      display: flex;
      flex-direction: column;
    }

    &_phone_error {
      margin-top: 4px;
      text-align: left;
    }
  }
}

@media screen and (max-width: 900px) {
  .container {
    .edit {
      &_main_box {
        grid-template-columns: 1fr;
      }

      &_password_box {
        grid-template-columns: 1fr;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .container {
    .data_list {
      .fieldvalue {
        font-size: 18px;
      }
    }

    .status_line_box_title {
      font-size: 18px;
    }

    .status_box {
      .progressbar {
        margin-top: 34px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .container {
    .edit {
      &_main_box {
        grid-template-columns: 1fr 1fr;
      }

      &_password_box {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .container {
    .edit {
      &_main_box {
        grid-template-columns: 1fr;
        gap: 30px;
      }

      &_password_box {
        grid-template-columns: 1fr;
        gap: 30px;
      }

      &_btns_box {
        margin-top: 35px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .container {
    .data_box {
      padding: 20px 20px 30px;
    }

    .data_list {
      grid-template-columns: 107px 1fr;

      .fieldvalue {
        font-size: 16px;
        font-weight: 700;
        line-height: 120%;
      }
    }

    .status_box {
      padding: 16px 12px 40px;

      .subtitle {
        font-size: 12px;
        line-height: 100%;
      }

      .progressbar {
        height: 2px;
        margin-top: 18px;
      }
    }

    .status_line_box {
      width: 95px;

      &_title {
        font-size: 16px;
        font-weight: 700;
        line-height: 120%;

        &_first {
          text-align: left;

          &::before {
            top: 48px;
            width: 10px;
            height: 10px;
          }
        }

        &_last {
          &::before {
            top: 48px;
            width: 10px;
            height: 10px;
          }
        }

        &_middle {
          &::before {
            top: 48px;
            width: 10px;
            height: 10px;
          }
        }

        &_active {
          &::before {
            top: 45px;
            width: 16px;
            height: 16px;
          }
        }
      }

      &_text {
        font-size: 11px;
        font-weight: 400;
        line-height: 100%;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .container {
    .btn_edit {
      width: 100%;
    }

    .edit {
      &_btns_box {
        flex-direction: column;
        gap: 20px;

        .btn_submit {
          width: 100%;
        }

        .btn_cancel {
          width: 100%;
        }
      }
    }
  }
}
