@import '../common';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: 1248px;
}

.link {
  margin: 10px auto 50px;
  text-decoration: none;
  transition: $common-transition;
}

.link:hover {
  opacity: $common-hover-opacity;
}

.text {
  display: flex;
  gap: 8px;
  align-items: center;

  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
  color: $text-gray;
}

.items_box {
  display: flex;
  gap: 9px;
  align-items: center;

  width: 100%;
  height: 264px;
}

.icon {
  fill: $text-gray;
}

@media screen and (max-width: 767px) {
  .items_box {
    flex-direction: column;
    height: 100%;
  }
}

@media screen and (max-width: 500px) {
  .title {
    align-self: flex-start;
    text-align: left;
  }

  .link {
    margin-bottom: 16px;
    margin-left: 0;
  }

  .text {
    text-align: left;
  }
}
