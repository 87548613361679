@import '../../components/common';

.container {
  box-sizing: border-box;
  width: 100%;
  max-width: 1274px;
  padding: 84px 24px 164px;

  .title {
    margin-bottom: 45px;
    text-align: left;
  }
}

.list {
  max-width: 1018px;
}

.accordion:last-of-type {
  border-bottom: 1px solid $text-black;
}

.input {
  color: red;
  background-color: red;
}

@media screen and (max-width: 900px) {
  .container {
    padding: 56px 24px 156px;
  }
}

@media screen and (max-width: 700px) {
  .container {
    padding-right: 16px;
    padding-left: 16px;

    .title {
      margin-bottom: 38px;
    }
  }
}
