@import '../../index';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  padding-bottom: 40px;
}

.main_content {
  display: flex;
  align-items: center;
  justify-content: space-between;

  box-sizing: border-box;
  width: 100%;
  max-width: 1287px;
  padding: 84px 9px 107px 24px;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  min-width: 360px;
  max-width: 584px;

  .contacts {
    margin-bottom: 40px;
  }

  .socials {
    gap: 15px;
  }
}

.title {
  margin-bottom: 24px;
}

.text {
  margin-bottom: 40px;
  color: $text-black;
  text-align: left;
}

.img_box {
  min-width: 560px;
}

.img {
  width: 100%;
  height: 100%;
}

.mobile_img {
  display: none;
}

@media screen and (max-width: 1000px) {
  .column {
    width: 360px;
  }

  .img_box {
    min-width: 0;
  }
}

@media screen and (max-width: 750px) {
  .img_box {
    display: none;
  }

  .mobile_img {
    display: block;
    width: 100%;
    margin-top: 38px;
    margin-bottom: 32px;
  }

  .column {
    width: 100%;
    min-width: 0;
    max-width: 100%;
  }

  .main_content {
    padding: 84px 24px 70px;
  }
}

@media screen and (max-width: 700px) {
  .main_content {
    padding: 84px 16px 70px;
  }
}

@media screen and (max-width: 650px) {
  .container {
    padding-bottom: 64px;
  }

  .main_content {
    padding: 56px 16px 138px;
  }
}
