@import '../../common';

.nav {
  display: flex;
  flex-direction: column;
  height: calc(100% - 60px);

  .list {
    display: flex;
    flex-direction: column;
    gap: 15px;

    margin: 0;
    padding: 0;

    &_btn {
      width: 100%;
      margin: 0;
      padding: 0;

      font-size: 16px;
      font-weight: 400;
      line-height: 105%;
      color: $text-white;

      background: none;
      border: none;
    }

    &_item {
      margin: 0;
      padding: 0;
      list-style-type: none;

      &_text {
        font-size: 16px;
        font-weight: 400;
        line-height: 105%;
        color: $text-white;
      }
    }

    &_link {
      position: relative;

      display: flex;
      gap: 9px;
      align-items: center;

      min-height: 45px;
      padding: 0 16px;

      text-decoration: none;

      transition: $common-transition;
    }

    &_link:hover {
      background-color: $gray-menu-mob;
    }

    .count {
      position: absolute;
      top: -3px;
      left: 46px;
    }

    .icon {
      margin: 0 9px;
    }
  }

  .logout_btn {
    box-sizing: border-box;
    width: calc(100% - 34px);
    margin: auto 17px 30px;
  }
}
