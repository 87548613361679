$common-switch-border-radius: 100px;
$common-extralarge-border-radius: 60px;
$common-tag-border-radius: 40px;
$common-large-border-radius: 33px;
$common-menu-border-radius: 16px;
$common-small-border-radius: 5px;
$common-checkbox-border-radius: 2px;
$common-transition: all 0.3s ease-in;
$common-hover-opacity: 0.8;
$common-disabled-opacity: 0.5;

// colors

$blue-gradient1: linear-gradient(105.05deg, #b36bff -14.86%, #5a75ff 104.8%);
$blue-gradient2: linear-gradient(
  105.05deg,
  rgb(179 107 255 / 24%) -14.86%,
  rgb(90 117 255 / 24%) 104.8%
);
$blue-gradient1-shadow: 0 0 12px 6px rgb(51 51 255 / 25%), 0 0 6px 6px rgb(51 51 255 / 25%);
$blue-gradient1-filter: drop-shadow(0 4px 32px rgb(51 51 255 / 50%));
$gray-gradient: linear-gradient(90deg, #bdc3c7 -23.98%, #2c3e50 100%);
$gray-gradient1-shadow: 0 0 16px 8px rgb(44 62 80 / 25%), 0 0 8px 8px rgb(44 62 80 / 25%);
$gray-gradient1-filter: drop-shadow(0 4px 32px rgb(44 62 80 / 50%));
$gray1: #d5dadc;
$gray2: #f4f4f4;
$gray3: #cdcdcd;
$gray4: #f6f6f6;
$gray5: #b8cad5;
$gray6: #545454;
$gray7: #f8f8f8;
$gray8: #f2f2f2;
$gray-menu: #eaeaea;
$gray-menu-mob: #3e3e3e;
$red1: #f00;
$red2: #d50000;
$red3: #d40000;
$red-bg: rgb(255 0 0 / 13%);
$orange-gradient: linear-gradient(90deg, #e91e1e 0%, #f8861e 100%);
$orange1: #ffbf2f;
$orange2: #ffb550;
$orange3: #fff2e2;
$black1: #1c1c1c;
$black2: #151515;
$green1: #4ccc49;
$green-bg: rgb(15 72 14 / 12%);
$text-black: #292929;
$text-dark: #636363;
$text-gray: #a3a3a3;
$text-white: #fff;
$overlay-color: rgb(14 14 14 / 20%);
$gradient-status-new: linear-gradient(105.05deg, #496ecc -14.86%, #7d35d8 104.8%);
$gradient-status-in-process: linear-gradient(90deg, #636363 -23.98%, #a0a0a0 100%);
$gradient-status-ready: linear-gradient(90deg, #49b4cc -23.98%, #3580d8 100%);
$gradient-status-sent: linear-gradient(90deg, #ff9212 -23.98%, #ffb800 100%);
$gradient-status-canceled: linear-gradient(90deg, #e32525 -23.98%, #f4600c 100%);
$gradient-status-received: linear-gradient(90deg, #4ccc49 -23.98%, #35d8b1 100%);
