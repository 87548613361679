@import '../../common';

.container {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
}

.input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.label {
  cursor: pointer;
  user-select: none;

  display: flex;
  gap: 12px;
  align-items: center;

  box-sizing: border-box;
  padding-right: 12px;

  font-size: 16px;
  font-weight: 400;
  line-height: 14px;
  color: $text-black;
  white-space: nowrap;
}

.label::before {
  content: '';

  display: block;
  flex-grow: 0;
  flex-shrink: 0;

  box-sizing: border-box;
  width: 16px;
  height: 16px;

  border: 1px solid $gray5;
  border-radius: $common-checkbox-border-radius;
}

input:checked + .label::before {
  background-image: url('../../../images/icons/check.svg'), $blue-gradient1;
  background-repeat: no-repeat;
  background-position: center center;
  border: none;
}

.quantity {
  padding-left: 12px;

  font-size: 16px;
  font-weight: 400;
  line-height: 14px;
  color: $text-dark;
  text-align: right;
}

.dotted {
  width: 100%;
  height: 14px;
  border-bottom: 1px dotted $gray3;
}
