.container {
  display: flex;
  flex-direction: column;
  gap: 150px;
  align-items: center;

  box-sizing: border-box;
  width: 100%;
  max-width: 1354px;
  padding: 40px 24px 110px;
}

@media screen and (max-width: 850px) {
  .container {
    gap: 100px;
  }
}

@media screen and (max-width: 700px) {
  .container {
    padding: 24px 16px 60px;
  }
}

@media screen and (max-width: 450px) {
  .container {
    gap: 60px;
  }
}
