@import '../../common';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  box-sizing: border-box;
  min-width: 290px;
  height: 48px;
  padding: 12px 20px;

  background: $blue-gradient2;
  border-radius: $common-large-border-radius;

  &_cart {
    min-width: 70px;
    height: 16px;
    padding: 0;
    background: none;
  }

  .input {
    width: 58px;
    height: 30px;
    padding: 6px;

    font-size: 18px;
    font-weight: 500;
    line-height: 130%;
    color: $text-black;
    text-align: right;

    background: none;
    border: none;
    outline: none;

    &_cart {
      width: 45px;
      text-align: center;
    }
  }
}

.btn {
  cursor: pointer;

  width: 24px;
  height: 24px;
  padding: 0;

  background: none;
  background-repeat: no-repeat;
  background-position: center;
  border: none;

  transition: $common-transition;

  &_minus {
    background-image: url('../../../images/icons/minus.svg');
    filter: opacity(0.5);

    &_cart {
      width: 11px;
      height: 10px;
    }
  }

  &_plus {
    background-image: url('../../../images/icons/plus.svg');
    filter: opacity(0.5);

    &_cart {
      width: 10px;
      height: 10px;
    }
  }

  &:disabled {
    cursor: auto;
    opacity: 0.4;
  }
}

.btn:hover {
  opacity: $common-hover-opacity;
}

.amount_box {
  display: flex;
  align-items: center;
  margin-left: -29px;

  &_cart {
    margin-left: 0;
  }
}

.amount {
  font-size: 18px;
  font-weight: 500;
  line-height: 130%;
  color: $text-black;
}

@media screen and (max-width: 850px) {
  .btn {
    &_minus {
      width: 19px;
      height: 19px;
    }

    &_plus {
      width: 19px;
      height: 19px;
    }
  }
}
