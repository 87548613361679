.container {
  display: flex;
  flex-direction: column;
  gap: 24px;

  box-sizing: border-box;
  width: 100%;
  max-width: 1366px;
  min-height: 600px;
  padding: 40px 24px 110px;

  .wrapper {
    padding: 16px 0 0;
  }

  .item_page {
    padding-right: 0;
    padding-left: 0;
  }
}

@media screen and (max-width: 700px) {
  .container {
    padding: 24px 16px 60px;

    .wrapper {
      padding: 0;
    }
  }
}
