@import '../../common';

.container {
  position: relative;

  padding: 8px;

  visibility: hidden;
  opacity: 0;
  background-color: $text-white;
  border-radius: $common-small-border-radius;
  box-shadow: 0 19px 28px 0 rgb(0 0 0 / 7%), 0 7px 9px 0 rgb(0 0 0 / 7%),
    0 1px 8px 0 rgb(0 0 0 / 7%), 0 5px 10px 0 rgb(0 0 0 / 7%), 0 0 32px 0 rgb(0 0 0 / 7%);

  transition: $common-transition;

  .text {
    font-size: 12px;
    font-weight: 400;
    line-height: 150%;
    color: $red1;
    white-space: nowrap;
  }

  .icon {
    position: absolute;
    bottom: -6px;
    left: 50%;
    translate: -50%;

    width: 12px;
    height: 6px;

    background-image: url('../../../images/icons/tooltip-icon.svg');
    background-repeat: no-repeat;
    background-position: center;
  }
}
