@import '../../common';

.modal {
  position: fixed;
  z-index: 11;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;

  box-sizing: border-box;
  min-width: 616px;
  padding: 36px 40px;

  visibility: hidden;
  opacity: 0;
  background-color: $text-white;
  border-radius: $common-large-border-radius;

  transition: $common-transition;

  &_small {
    width: 400px;
    min-width: 400px;
  }

  &_open {
    visibility: visible;
    opacity: 1;
  }
}

.overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  visibility: hidden;
  opacity: 0;
  background-color: $overlay-color;

  transition: $common-transition;

  &_open {
    visibility: visible;
    opacity: 1;
  }
}

.close_btn {
  position: absolute;
  z-index: 2;
  top: 24px;
  right: 24px;

  &_dis {
    visibility: hidden;
    opacity: 1;
  }
}

.body {
  overflow: hidden;
}

@media screen and (max-width: 650px) {
  .modal {
    min-width: 450px;
  }
}

@media screen and (max-width: 450px) {
  .modal {
    top: 68px;
    left: 0;
    transform: none;

    width: 100%;
    min-width: 100%;
    height: calc(100dvh - 68px);
    padding: 24px 14px;

    visibility: visible;
    opacity: 1;
    border-radius: 0;

    transition: $common-transition;
  }

  .overlay_open {
    visibility: visible;
    opacity: 1;
  }
}
