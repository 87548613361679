@import '../../common';

.container {
  overflow-x: auto;
  display: flex;
  gap: 20px;

  box-sizing: border-box;
  width: 100%;
  padding: 16px 0;
}

// .container::-webkit-scrollbar {
//   display: none;
// }

.img_box {
  position: relative;
}

.img {
  cursor: pointer;

  box-sizing: border-box;
  width: 84px;
  height: 84px;

  object-fit: contain;
  border-radius: $common-small-border-radius;

  &_active {
    border: 1px solid $gray3;
  }

  &:hover {
    border: 1px solid $gray3;
  }
}

.tag {
  position: absolute;
  right: 12px;
  bottom: 0;

  width: 24px;
  padding: 4px;

  font-size: 14px;
  font-weight: 700;
  line-height: 100%;
}

.stock_off {
  opacity: 0.5;
}

@media screen and (max-width: 500px) {
  .container {
    gap: 15px;
  }

  .img {
    width: 70px;
    height: 70px;
  }
}
