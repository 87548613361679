@import '../../common';

.input {
  box-sizing: border-box;
  height: 51px;
  padding: 16px;

  font-size: 16px;
  font-weight: 400;
  line-height: 120%;
  color: $text-black;

  background-color: $text-white;
  border: 1px solid $gray3;
  border-radius: $common-small-border-radius;

  &_error {
    color: $red1;
    border: 1px solid $red1;
  }
}

.input::placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 120%;
  color: $text-gray;
}

.input:focus {
  background-image: linear-gradient(white, white), $blue-gradient1;
  background-clip: padding-box, border-box;
  background-origin: border-box;
  border: double 1px transparent;
  border-radius: $common-small-border-radius;
  outline: none;
}
