.header {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
}

.footer {
  margin-top: auto;
}
