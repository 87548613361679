@import '../common';

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  box-sizing: border-box;
  min-width: 450px;
  max-width: 35%;
}

.subdata {
  display: flex;
  gap: 16px;
  margin-top: 4px;
  margin-bottom: 17px;
}

.title {
  text-align: left;
}

.photos_box {
  margin-top: 16px;
}

.description_title {
  margin-top: 16px;
  margin-bottom: 8px;
}

.description_list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;

  margin: 0;
  margin-bottom: 24px;
  padding: 0;

  &_item {
    display: flex;
    gap: 8px;
    align-items: center;

    font-size: 15px;
    font-weight: 400;
    line-height: 150%;
    color: $text-dark;
    list-style-type: none;
  }

  &_item::before {
    content: '';

    width: 6px;
    height: 6px;

    background: $orange-gradient;
    border-radius: 50%;
  }
}

.btn_box {
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 290px;
}

.swiper {
  display: none;
  margin-top: 32px;
  padding-bottom: 25px;
}

.img {
  width: 100%;
}

@media screen and (max-width: 850px) {
  .container {
    max-width: 100%;
    padding: 0;
  }
}

@media screen and (max-width: 500px) {
  .container {
    width: 100%;
    min-width: fill-available;
  }
}

@media screen and (max-width: 450px) {
  .swiper {
    display: block;
    width: 100%;
  }
}

@media screen and (max-width: 425px) {
  .btn_box {
    width: 100%;
  }
}
