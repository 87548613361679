.container {
  display: flex;
  flex-direction: column;
  gap: 24px;

  box-sizing: border-box;
  width: 100%;
  max-width: 1274px;
  margin: 0 auto;
  padding: 40px 24px 110px;
}

@media screen and (max-width: 700px) {
  .container {
    padding: 24px 16px 60px;
  }
}
