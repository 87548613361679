@import './components/common';

body {
  margin: 0;

  font-family: 'SF Pro Display', sans-serif;
  font-style: normal;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button,
input {
  font-family: 'SF Pro Display', sans-serif;
}

.swiper {
  width: 100%;
  height: 100%;
}

.slick-slide > div {
  display: flex;
  justify-content: center;
}

.swiper-button-next,
.swiper-button-prev {
  right: -46px;
  width: 46px;
  height: 46px;
  transition: all 0.3s;
}

.swiper-button-prev {
  left: -46px;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  opacity: 0.7;
}

.swiper-button-next::after {
  content: url('./images/icons/next.svg');
  display: block;
  height: 46px;
}

.swiper-button-prev::after {
  content: url('./images/icons/prev.svg');
  display: block;
  height: 46px;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  pointer-events: auto;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled:hover,
.swiper-button-next.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled:hover {
  opacity: 0.35;
}

.swiper-pagination {
  position: absolute;
  bottom: 20px;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  opacity: 1;
  background: $text-gray;
}

.swiper-pagination-bullet-active {
  box-sizing: border-box;
  width: 14px;
  height: 14px;

  background: none;
  border: 2px solid $text-gray;
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 8px;
}

.PhoneInputCountry {
  padding: 8px;
  border: 1px solid $gray3;
  border-radius: $common-small-border-radius;

  .PhoneInputCountrySelect {
    height: 51px;
  }
}

.PhoneInput {
  width: 100%;
}

.rc-slider-rail {
  height: 2px;
  background-color: $gray5;
}

.rc-slider-track {
  height: 2px;
  background: $blue-gradient1;
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  box-shadow: 0 0 5px 4px rgb(90 117 255 / 60%);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
  appearance: none;
}

input[type='number'] {
  appearance: textfield;
}

// @media screen and (max-width: 600px) {
//   .swiper .swiper-pagination {
//     bottom: 0;
//   }

//   .swiper {
//     padding-bottom: 36px;
//   }
// }

@media screen and (max-width: 500px) {
  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }

  .slick-slide > div {
    gap: 20px;

    &:nth-child(2) {
      margin-top: 20px;
    }
  }
}

// @media (pointer: fine) {
//   body {
//     scrollbar-color: $blue-gradient1 $text-white;
//     scrollbar-width: thin;
//     padding: 0 calc(5px - (100vw - 100%)) 0 0;
//   }

//   body::-webkit-scrollbar {
//     width: 5px;
//   }

//   body::-webkit-scrollbar-track {
//     background: $text-white;
//   }

//   body::-webkit-scrollbar-thumb {
//     width: 3px;
//     background: $blue-gradient1;
//     border-radius: 6px;
//   }
// }
