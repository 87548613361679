@import '../../index';

.container {
  display: flex;
  flex-direction: column;
  gap: 150px;
  align-items: center;

  width: 100%;
  padding-bottom: 150px;
}

.swiper {
  position: relative;
  max-width: 100%;
}

.main {
  display: flex;
  flex-direction: column;
  gap: 150px;
  align-items: center;

  box-sizing: border-box;
  width: 100%;
  padding: 0 16px;
}

@media screen and (max-width: 500px) {
  .container {
    gap: 70px;
    padding-bottom: 68px;
  }

  .main {
    gap: 70px;
  }
}
