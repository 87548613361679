@import '../common';

.container {
  padding: 27px;
  border: 1px solid $gray3;
  border-radius: $common-small-border-radius;

  .row {
    display: flex;
    align-items: flex-start;

    .order {
      &_num_box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      &_num {
        font-size: 28px;
        font-weight: 500;
        line-height: 150%;
        color: $text-black;
      }

      &_date {
        font-size: 14px;
        font-weight: 400;
        line-height: 100%;
      }

      &_price_box {
        margin-left: auto;
      }

      &_price {
        font-size: 22px;
        font-weight: 600;
        line-height: 100%;
        color: $text-black;
      }
    }

    .status {
      margin-top: 6px;
      margin-left: 24px;

      &_mob {
        display: none;
      }
    }

    .btn_cancel_box {
      position: relative;
      margin-top: 6px;
      margin-left: 17px;

      .tooltip {
        position: absolute;
        top: -46px;
        left: -120%;
      }
    }

    .btn_cancel {
      cursor: pointer;

      display: flex;

      width: 32px;
      height: 32px;
      padding: 0;

      background-color: rgb(255 0 0 / 13%);
      border: none;
      border-radius: 50%;

      transition: $common-transition;

      &:hover {
        opacity: $common-hover-opacity;

        & ~ .tooltip {
          visibility: visible;
          opacity: 1;
        }
      }

      &_icon {
        margin: auto;
        fill: $red1;
      }
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: 16px;

    margin: 52px 0 25px;
    padding: 0;
  }
}

@container (width <= 550px) {
  .container {
    .row {
      .btn_cancel_box {
        margin-left: 12px;
      }

      .tooltip {
        display: none;
      }

      .status {
        display: none;

        &_mob {
          display: block;
          margin-top: 8px;
        }
      }
    }
  }
}

@container (width <= 450px) {
  .container {
    padding: 0;
    border: none;

    .row {
      .order {
        &_num {
          font-size: 20px;
          font-weight: 600;
          line-height: 100%;

          &_box {
            gap: 8px;
          }
        }

        &_date {
          font-size: 16px;
          font-weight: 400;
        }

        &_price {
          font-size: 16px;
          font-weight: 700;
          line-height: 120%;
        }
      }

      .btn_cancel_box {
        margin-top: 0;
      }
    }

    .list {
      margin: 34px 0 32px;
    }
  }
}

@media screen and (max-width: 500px) {
  .container {
    margin-top: 50px;
  }
}
