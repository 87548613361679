@import '../../common';

.container {
  cursor: pointer;

  width: fit-content;
  padding: 14px;

  background-image: $blue-gradient1;
  border: none;

  transition: $common-transition;

  &:hover {
    box-shadow: $blue-gradient1-shadow;
  }

  &:disabled {
    cursor: default;
    background: $gray3;
    filter: none;
    box-shadow: none;
  }

  .text {
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    line-height: 14px;
    color: $gray2;
  }
}
