@import '../../common';

.btn {
  cursor: pointer;

  display: flex;
  align-items: center;

  width: 100%;
  padding: 20px 15px;

  background: none;
  border: none;

  transition: $common-transition;

  &:hover {
    background-color: $gray-menu;
  }
}

.title {
  font-size: 20px;
  font-weight: 600;
  line-height: 105%;
  color: $text-black;
  text-align: left;
  white-space: nowrap;
}

.img {
  transform: rotate(-90deg);
  margin-left: auto;
  fill: $text-black;
  transition: $common-transition;

  &_open {
    transform: rotate(90deg);
  }
}

.list {
  overflow: hidden;

  max-height: 0;
  margin: 0;
  padding: 0;

  transition: $common-transition;

  &_item {
    list-style-type: none;
  }
}

.link {
  display: flex;

  padding: 22px 50px;

  font-size: 20px;
  font-weight: 400;
  line-height: 105%;
  color: $text-black;
  text-align: left;
  text-decoration: none;
  white-space: nowrap;

  transition: $common-transition;

  &:hover {
    background-color: $gray-menu;
  }
}

@media screen and (max-width: 600px) {
  .btn {
    &:hover {
      background-color: $gray-menu-mob;
    }
  }

  .title {
    color: $text-white;
  }

  .img {
    fill: $text-white;
  }

  .link {
    color: $text-white;
  }
}

@media screen and (max-width: 500px) {
  .title {
    font-size: 16px;
  }

  .link {
    font-size: 16px;
  }
}
