@import '../common';

.form {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: 400px;
}

.input {
  width: 100%;
  margin-bottom: 15px;
}

.title {
  margin-bottom: 4px;
}

.error {
  align-self: flex-start;
  height: 17px;
  margin-bottom: 4px;
  visibility: hidden;

  &_active {
    visibility: visible;
  }
}

@media screen and (max-width: 900px) {
  .form {
    max-width: 320px;
  }
}

@media screen and (max-width: 750px) {
  .form {
    max-width: 400px;
  }
}

@media screen and (max-width: 450px) {
  .form {
    max-width: 320px;
  }
}
