@import '../../common';

.title {
  margin: 0;
  color: $text-black;
  text-align: center;

  &_h3 {
    font-size: 32px;
    font-weight: 600;
    line-height: 146.84%;
  }

  &_h4 {
    font-size: 28px;
    font-weight: 500;
    line-height: 150%;
  }

  &_h5 {
    font-size: 22px;
    font-weight: 600;
    line-height: 100%;
  }

  &_h6 {
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    color: $text-black;
  }
}

@media screen and (max-width: 650px) {
  .title {
    &_h4 {
      font-size: 26px;
      line-height: 125%;
    }

    &_h3 {
      font-size: 26px;
      line-height: 130%;
    }
  }
}
