@import '../common';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  box-sizing: border-box;
  width: 100%;
  max-width: 1320px;
  padding: 0 46px;
}

.link {
  margin-top: 10px;
  text-decoration: none;
  transition: $common-transition;
}

.link:hover {
  opacity: $common-hover-opacity;
}

.text {
  display: flex;
  gap: 8px;
  align-items: center;

  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
  color: $text-gray;
}

.swiper {
  &_box {
    position: relative;
    width: 100%;
    margin-top: 40px;
  }
}

.slide {
  max-width: 292px;
  margin-right: 13px;
}

.slide_brand {
  max-width: 288px;
  margin-right: 24px;

  &:last-child {
    margin-right: 0;
  }
}

.btn_mobile {
  display: none;
}

.list_mobile {
  display: none;
  margin: 0;
  padding: 0;
}

.icon {
  fill: $text-gray;
}

@media screen and (max-width: 500px) {
  .container {
    padding: 0;
  }

  .swiper_box {
    display: none;
  }

  .link {
    display: none;
  }

  .title {
    align-self: flex-start;
    margin-bottom: 16px;
    text-align: left;
  }

  .btn_mobile {
    display: block;
    margin-top: 16px;
  }

  .list_mobile {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  .swiper {
    width: 100%;

    & > div > div > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 16px;
    }
  }

  .slide {
    max-width: 100%;
  }
}
