@import '../common';

.signin {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  width: 123px;
  height: 47px;

  background-image: url('../../images/logo.svg');
  background-position: center;
  background-size: 132%;
}

.tabs {
  margin-bottom: 24px;
}

.add_margin {
  margin-bottom: 27px;
}

.text {
  margin-top: 46px;
  margin-bottom: 16px;
  font-weight: 500;
}
