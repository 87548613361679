@import '../common';

.container {
  width: 100%;
  max-width: 1226px;
  margin: 0 auto;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 393px 355px 322px;
  gap: 20px;

  &_item {
    cursor: pointer;

    color: $text-black;
    text-decoration: none;

    background-color: $gray2;
    background-repeat: no-repeat;
    background-position: center bottom;
    border-radius: 6px;

    &_1 {
      display: flex;
      grid-column: 1/6;
      grid-row: 1/2;
      flex-direction: column;
      align-items: flex-start;
    }

    &_2 {
      grid-column: 6/10;
      grid-row: 1/2;
    }

    &_3 {
      grid-column: 10/13;
      grid-row: 1/2;
    }

    &_4 {
      grid-column: 1/5;
      grid-row: 2/3;
    }

    &_5 {
      grid-column: 5/10;
      grid-row: 2/3;
    }

    &_6 {
      display: flex;
      grid-column: 10/13;
      grid-row: 2/4;
      align-items: center;
      justify-content: center;
    }

    &_7 {
      grid-column: 1/4;
      grid-row: 3/4;
    }

    &_8 {
      grid-column: 4/7;
      grid-row: 3/4;
    }

    &_9 {
      grid-column: 7/10;
      grid-row: 3/4;
    }

    &_title {
      margin-top: 34px;

      &_1 {
        padding-left: 40px;
      }

      &_6 {
        margin-top: 15px;
        text-align: center;
      }
    }
  }
}

.title {
  margin-bottom: 30px;
}

@media screen and (max-width: 750px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(20, 40px);
    gap: 12px;

    &_item {
      display: flex;
      align-items: flex-end;
      justify-content: center;

      height: 100%;

      background-position: center;
      background-size: auto 50%;

      &_mob {
        &_1 {
          grid-column: 1/2;
          grid-row: 1/5;
          flex-direction: row;
          align-items: flex-end;
          justify-content: center;
        }

        &_2 {
          grid-column: 2/3;
          grid-row: 1/5;
        }

        &_3 {
          grid-column: 1/2;
          grid-row: 5/12;
          align-items: center;
          background-size: auto 90%;
        }

        &_4 {
          grid-column: 2/3;
          grid-row: 5/9;
        }

        &_5 {
          grid-column: 2/3;
          grid-row: 9/13;
        }

        &_6 {
          grid-column: 1/2;
          grid-row: 12/16;
        }

        &_7 {
          grid-column: 2/3;
          grid-row: 13/17;
        }

        &_8 {
          grid-column: 1/2;
          grid-row: 16/20;
        }

        &_9 {
          grid-column: 2/3;
          grid-row: 17/21;
        }
      }

      &_title {
        margin: 0;
        margin-bottom: 16px;
        font-size: 16px;

        &_1 {
          padding-left: 0;
        }

        &_6 {
          margin-top: 0;
          margin-bottom: 24px;
          text-align: center;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .title {
    align-self: flex-start;
    margin-bottom: 16px;
    text-align: left;
  }
}
