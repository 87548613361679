@import '../../common';

.container {
  width: 100%;
  max-width: 291px;
  list-style-type: none;
}

.link {
  display: block;

  width: 100%;
  height: 128px;

  background-color: $gray2;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border-radius: $common-small-border-radius;
}

.text {
  margin-top: 16px;

  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  line-height: 105%;
  color: $text-black;
}

@media screen and (max-width: 450px) {
  .container {
    max-width: 100%;
  }
}
