@import '../../components/common';

.container {
  box-sizing: border-box;
  width: 100%;
  max-width: 1226px;
  padding: 82px 24px 127px;
}

@media screen and (max-width: 700px) {
  .container {
    padding: 50px 16px 68px;
  }
}

@media screen and (max-width: 500px) {
  .container {
    padding-top: 24px;
    padding-bottom: 45px;
  }
}
