@import '../common';

.header {
  width: 100%;
  background-color: $black1;

  &_search {
    z-index: 4;
  }
}

.container {
  position: relative;

  display: flex;
  align-items: center;

  box-sizing: border-box;
  width: 100%;
  max-width: 1274px;
  margin: 0 auto;
  padding: 32px 24px;

  .search_box {
    position: relative;
    width: 50%;

    .menu_popup_search {
      position: absolute;
      z-index: 3;
      top: 24px;
      left: 0;

      display: flex;
      flex-direction: column;
      align-items: flex-start;

      box-sizing: border-box;
      width: 100%;
      padding: 24px 0 0;

      border-radius: 0 0 $common-menu-border-radius $common-menu-border-radius;

      &_overlay {
        z-index: 2;
      }
    }
  }

  .search_error {
    padding: 14px 24px;
  }
}

.top_line {
  width: calc(100% - 48px);
  height: 1px;
  margin: 0 24px;
  background-color: $text-gray;
}

.logo {
  width: 123px;
  margin-right: 32px;
}

.btn {
  margin-right: 44px;
}

.nav {
  margin-left: auto;
}

.menu_box {
  position: relative;

  .menu_popup {
    &_main {
      position: absolute;
      top: 60px;
      left: 0;

      &_opened {
        border-radius: 16px 0 0 16px;
      }
    }

    &_list {
      margin: 0;
      padding: 0;
    }

    &_children {
      position: absolute;
      top: 60px;
      left: 430px;
      border-radius: 0 $common-menu-border-radius $common-menu-border-radius 0;
    }
  }
}

.body {
  overflow: hidden;
}

.heightBlock {
  height: 115px;
}

@media screen and (max-width: 1050px) {
  .logo {
    margin-right: 16px;
  }

  .btn {
    margin-right: 24px;
  }

  .nav {
    margin-left: 32px;
  }

  .menu_box {
    .menu_popup {
      &_children {
        left: 300px;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .container {
    padding: 28px 24px;
  }

  .logo {
    width: 100px;
  }

  .nav {
    margin-left: 24px;
  }

  .menu_box {
    .menu_popup {
      &_children {
        left: 200px;
      }
    }
  }

  .heightBlock {
    height: 103px;
  }
}

@media screen and (max-width: 800px) {
  .btn {
    margin-right: 16px;
  }

  .nav {
    margin-left: 16px;
  }

  .menu_box {
    .menu_popup {
      &_children {
        left: 130px;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .container {
    justify-content: space-between;
  }

  .btn {
    margin: 0;
  }

  .link {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .logo {
    margin: 0;
  }

  .nav {
    margin: 0;
  }

  .search_box {
    display: none;
  }
}

@media screen and (max-width: 700px) {
  .container {
    padding: 16px;
  }

  .heightBlock {
    height: 79px;
  }
}

@media screen and (max-width: 600px) {
  .container {
    .menu_box {
      .menu_popup {
        &_main {
          position: fixed;
          top: 0;
          left: 0;

          width: 100%;
          height: 100dvh;
        }

        &_children {
          position: fixed;
          top: 0;
          left: 0;

          width: 100%;
          height: 100dvh;
        }
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .heightBlock {
    height: 68px;
  }
}
