@import '../../common';

.container {
  position: relative;

  .label {
    position: absolute;
    z-index: 100;
    left: 290px;
    white-space: nowrap;
  }
}

.btn {
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  margin-bottom: 16px;
  padding: 1px 16px;

  background: none;
  border: none;
}

.img {
  transform: rotate(180deg);
  transition: $common-transition;

  &_open {
    transform: rotate(0);
  }
}

.list {
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  gap: 16px;

  max-height: 0;
  margin: 0;
  padding: 0 16px;

  transition: $common-transition;

  &_open {
    margin-bottom: 16px;
  }

  &_item {
    position: relative;
    list-style-type: none;
  }
}
