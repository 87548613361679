.main_box {
  display: flex;
  gap: 54px;
  align-items: flex-start;
  justify-content: space-between;

  box-sizing: border-box;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 64px;

  box-sizing: border-box;
  width: 100%;
}

@media screen and (max-width: 850px) {
  .main_box {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }
}

@media screen and (max-width: 450px) {
  .main_box {
    .gallery {
      display: none;
    }
  }

  .container {
    gap: 24px;
  }
}
