@import '../common';

.title {
  margin-bottom: 16px;
  line-height: 1;
}

.text {
  max-width: 300px;

  font-size: 16px;
  font-weight: 400;
  line-height: 120%;
  color: $text-black;
}

.btn_box {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  align-self: flex-end;

  width: 100%;
  margin-top: 50px;

  .btn {
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .btn_box {
    margin-top: auto;
  }

  .title {
    align-self: flex-start;
  }

  .text {
    align-self: flex-start;
    text-align: left;
  }
}
