@import '../../common';

.brand {
  width: 100%;
  list-style-type: none;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 128px;

  background-color: $gray2;
  border-radius: 6px;

  .img {
    width: 100%;
    max-width: 80%;
    height: 100%;
    max-height: 60%;

    object-fit: contain;
  }
}

@media screen and (max-width: 500px) {
  .link {
    height: 90px;
  }
}

@media screen and (max-width: 380px) {
  .link {
    height: 70px;
  }
}
