@import '../../common';

.container {
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: 10px;

  box-sizing: border-box;
  max-height: 603px;
}

.img {
  cursor: pointer;

  box-sizing: border-box;
  width: 106px;
  height: 107px;
  padding: 12px;

  object-fit: contain;
  border-radius: $common-small-border-radius;

  &_active {
    border: 1px solid $gray3;
  }

  &:hover {
    border: 1px solid $gray3;
  }
}

.container::-webkit-scrollbar {
  width: 0;
}

@media screen and (max-width: 1000px) {
  .container {
    overflow-x: auto;
    overflow-y: hidden;
    flex-direction: row;
    width: 100%;
  }
}

@media screen and (max-width: 850px) {
  .container {
    width: fit-content;
    max-width: calc(100% - 32px);
  }
}
