@import '../../components/common';

.container {
  box-sizing: border-box;
  width: 100%;
  max-width: 1274px;
  min-height: calc(100vh - 112px - 490px);
  padding: 82px 24px 115px;

  .title {
    text-align: left;
  }

  .nav {
    font-size: 20px;
    font-weight: 600;
    font-style: normal;
    line-height: 120%;

    .links {
      display: flex;
      flex-direction: column;
      gap: 20px;

      margin: 0;
      padding: 0;

      .li {
        list-style-type: none;
      }
    }

    .icon {
      min-height: 32px;

      &_active {
        position: absolute;
        top: 0;
        left: 0;

        min-height: 32px;

        visibility: hidden;
        opacity: 0;
      }
    }

    .link {
      position: relative;

      display: flex;
      flex-direction: column;

      color: $text-black;
      text-decoration: none;

      &_container {
        display: flex;
        gap: 8px;
        align-items: center;
      }

      &_active {
        background-image: $blue-gradient1;
        background-clip: text;

        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;

        .icon {
          visibility: hidden;
          opacity: 0;
        }

        .icon_active {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  .main {
    display: grid;
    grid-template-columns: 250px 1fr;
    gap: 60px;
    margin-top: 32px;
  }

  .right_column {
    container-type: inline-size;
  }

  .logout {
    cursor: pointer;

    width: fit-content;
    padding: 0;

    font-size: 20px;
    font-weight: 600;
    font-style: normal;
    line-height: 120%;
    text-align: left;

    background: none;
    border: none;
  }
}

@media screen and (max-width: 1100px) {
  .container {
    .main {
      gap: 40px;
    }
  }
}

@media screen and (max-width: 800px) {
  .container {
    padding: 40px 0;

    .title {
      display: none;
    }

    .main {
      display: flex;
      flex-direction: column;
      gap: 40px;

      .right_column {
        padding: 0 24px;
      }
    }

    .nav {
      box-sizing: border-box;
      width: fit-content;
      max-width: 100%;
      white-space: nowrap;

      .links {
        overflow-x: scroll;
        display: flex;
        flex-direction: row;
        gap: 0;

        &::-webkit-scrollbar {
          width: 0;
        }

        .link {
          padding-bottom: 9px;

          &_active {
            -webkit-text-fill-color: $text-black;
            -moz-text-fill-color: $text-black;

            &::after {
              content: '';

              position: relative;
              top: 12px;

              display: block;

              height: 4px;

              background: $blue-gradient1;
            }

            .icon {
              visibility: visible;
              opacity: 1;
            }

            .icon_active {
              visibility: hidden;
              opacity: 0;
            }
          }
        }
      }

      .li {
        padding-bottom: 2px;
        padding-left: 22px;
        border-bottom: 1px solid $text-gray;

        &:first-child {
          margin-left: 24px;
          padding-left: 0;
        }

        &:last-child {
          margin-right: 24px;
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .container {
    padding: 20px 0 60px;

    .main {
      margin-top: 0;

      .right_column {
        padding: 0 16px;
      }
    }

    .nav {
      margin-top: 0;

      .li {
        &:first-child {
          margin-left: 16px;
        }

        &:last-child {
          margin-right: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .container {
    .nav {
      .links {
        .li {
          &:last-child {
            display: none;
          }

          &:nth-child(3) {
            margin-right: 16px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .container {
    .main {
      gap: 25px;
    }
  }
}
