@import '../../common';

.container {
  user-select: none;

  box-sizing: border-box;
  padding: 4px 8px;

  font-size: 11px;
  font-weight: 400;
  line-height: 120%;
  color: $text-white;

  background: $orange-gradient;
  border-radius: $common-tag-border-radius;
}

.black_label {
  background: $text-black;
  border-radius: $common-small-border-radius;
}
