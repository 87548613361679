.container {
  display: flex;
  gap: 94px;
  align-items: flex-start;
  width: 100%;
}

@media screen and (max-width: 1100px) {
  .container {
    gap: 30px;
  }
}

@media screen and (max-width: 1000px) {
  .container {
    flex-direction: column;
    width: 100%;
    max-width: 45%;

    // padding-left: 16px;
  }
}

@media screen and (max-width: 950px) {
  .container {
    max-width: 40%;
  }
}

@media screen and (max-width: 850px) {
  .container {
    gap: 10px;
    align-items: center;
    max-width: 100%;
    padding: 0;
  }

  .photo {
    display: flex;
    justify-content: center;
    order: -1;
  }
}
