@import '../../common';

.tab {
  cursor: pointer;
  user-select: none;

  width: 100%;
  padding: 8px 25px;

  font-size: 18px;
  font-weight: 400;
  line-height: 130%;
  color: $text-black;
  text-align: center;

  background: none;
  border: none;
  border-radius: $common-small-border-radius;

  transition: $common-transition;

  &_active {
    pointer-events: none;
    background-color: $gray1;
  }
}
