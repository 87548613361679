@import '../../common';

.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.img {
  width: 100%;
  max-width: 569px;
  height: 100%;
  height: 603px;

  object-fit: contain;
}

.additions {
  position: absolute;
  top: 5px;
  right: 21px;

  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-end;
}

.like {
  cursor: pointer;

  width: 35px;
  height: 35px;

  background: none;
  background-image: url('../../../images/icons/favorite.svg');
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  outline: none;

  transition: $common-transition;

  &_active {
    background-image: url('../../../images/icons/favorite-active.svg');
  }

  &:disabled {
    animation: like 0.6s infinite;
  }
}

.like:hover {
  opacity: $common-hover-opacity;
}

.tabs {
  &_mobile {
    position: absolute;
    top: 5px;
    left: 0;
    display: none;
  }
}

@media screen and (max-width: 550px) {
  .img {
    height: 328px;
  }
}

@media screen and (max-width: 450px) {
  .tabs {
    display: none;

    &_mobile {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-items: flex-start;
    }
  }
}

@keyframes like {
  33% {
    scale: 1.2;
  }

  66% {
    scale: 0.8;
  }

  100% {
    scale: 1;
  }
}
