.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.slide {
  width: 300px;
  height: 200px;
  background-color: red;
}
