@import '../../common';

.container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;

  width: 100%;
}

.pagination_box {
  display: flex;
  gap: 6px;
  align-items: center;
  height: 31px;
}

.btn {
  cursor: pointer;
  background: none;
  border: none;
  transition: $common-transition;

  &_arrow {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 32px;
    height: 100%;

    &_right {
      transform: rotate(180deg);
    }
  }

  &_num {
    box-sizing: border-box;
    width: 24px;
    padding: 4px 6px;
  }
}

.btn:hover {
  opacity: $common-hover-opacity;
}

.btn:disabled {
  cursor: default;
}

.btn_arrow:disabled {
  opacity: 0.5;
}

.btn_num:disabled {
  opacity: 1;
}

.text {
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  color: $gray3;

  &_active {
    font-size: 18px;
    font-weight: 700;
    color: $text-black;
  }
}
